import Alpine from 'alpinejs'
import { ActivePage } from '../enums/ActivePage'

const state = Alpine.reactive({
    activePage: ActivePage.Landing,
})

export class Navigation {

    /**
     * TODO: Refactor to get web form requests happening properly and being properly associated with the landing for ref
     * Also need to update the router to handle the new web form routes
     */

    init(formPath, bypassLoadingPage, hasInlineWebForm, isInlineWithRedirect, hasGroups, frontendRoutePath, isChat, startWebFormRedirectUrl) {

        initRouter(frontendRoutePath, hasInlineWebForm)
        this.frontendRoutePath = frontendRoutePath
        this.formPath = formPath || ''
        this.bypassLoadingPage = bypassLoadingPage || false
        this.hasInlineWebForm = hasInlineWebForm ?? false
        this.isInlineWithRedirect = isInlineWithRedirect ?? false
        this.hasGroups = hasGroups ?? true
        this.step = this.hasInlineWebForm ? 1 : 0
        this.isChat = isChat ?? false
        this.startWebFormRedirectUrl = startWebFormRedirectUrl ?? null
        Alpine.watch(() => Alpine.$router.path, (newPath, oldPath) => handleRoute(newPath, oldPath, this.formPath))
        window.addEventListener('popstate', () => handleStep())

    }

    get activePage() {
        return state.activePage
    }

    set activePage(value) {
        state.activePage = value
    }

    start() {

        if (this.startWebFormRedirectUrl) {

            window.location.href = this.startWebFormRedirectUrl

            return true

        }

        if (this.isChat) {

            Alpine.store('qv').chat.controller.start()

            return true

        }

        if (this.hasInlineWebForm) {
            Alpine.store('qv').webForm.state.step = 1
        }

        Alpine.store('qv').webForm.controller.start()

        return true

    }

    goTo(path, replace) {

        const method = replace ? 'replace' : 'push'

        Alpine.$router[ method ](path)

        if (!this.hasInlineWebForm) {
            scrollToTop()
        }

    }

    goToChat() {
        this.goTo(buildFullPath(this.frontendRoutePath, 'chat'), true)
    }

    goToStep(step, replace) {
        this.goTo(buildFullPath(this.frontendRoutePath, step), replace)
    }

    goToLoadingSteps() {
        this.goTo(buildFullPath(this.frontendRoutePath, 'loading'))
    }

    goToThankYouPage() {
        this.goTo(buildFullPath(this.frontendRoutePath, 'thank-you'))
    }

    scrollToTop() {
        return scrollToTop()
    }

    /**
     * @param {string} uri
     */
    redirectTo(uri) {
        window.location.href = uri
    }

}

function handleRoute(newPath, oldPath, formPath) {

    /**
     * If the user reaches the loading or thank-you page and tries to go back
     * we'll redirect them to the start of the web-form.
     */
    if (oldPath.endsWith('/thank-you') || (oldPath.endsWith('/loading') && !newPath.endsWith('/thank-you'))) {
        window.location = formPath
    }

}

function handleStep() {

    Alpine.nextTick(() => {

        const currentStep = parseInt(Alpine.$router.params.step)
        const cachedStep = Alpine.store('qv').webForm.controller.cachedSteps[ currentStep ]

        Alpine.store('qv').webForm.state.step = currentStep

        if (cachedStep) {

            Alpine.store('qv').webForm.state.webFormContent = cachedStep
            Alpine.store('qv').webForm.state.canSubmit = true

        }

    })

}

function initRouter(frontendRoutePath, hasInlineWebForm) {

    const $router = Alpine.$router

    $router.addRoutes([
        '/landing-page-preview/:slug/?',
        `${ frontendRoutePath }/:slug/?`,
        `${ frontendRoutePath }/:slug/loading/?`,
        `${ frontendRoutePath }/:slug/thank-you/?`,
        `${ frontendRoutePath }/:slug/chat/?`,
        `${ frontendRoutePath }/:slug/step/:step(\\d+)?/?`,
        `${ frontendRoutePath }/:slug/:step(\\d+)?/?`,
    ])

    $router.afterEach(() => {

        /**
         * Smooth scrolling can get cancelled if the content length has a large change.
         * This is a workaround to ensure the page is scrolled to the top if the
         * scroll was silently cancelled.
         *
         * This is duplicated from how our Vue implementation works.
         */
        if (hasInlineWebForm && Alpine.store('qv').navigation.activePage === ActivePage.Landing) {
            return
        }

        scrollToTop()

    })

    openModalByRoute($router)

}

function openModalByRoute($router) {

    setTimeout(() => {

        const modalId = $router.hash.replace(new RegExp('^#'), '')

        if (!modalId) {
            return
        }

        showDynamicModalById(modalId)

    }, 400)

}

function showDynamicModalById(modalId) {

    if (modalId === 'contact') {
        return Alpine.store('qv').modal.openContactUsModal()
    }

    Alpine.store('qv').modal.openModal(modalId)

}

function scrollToTop() {
    window.scrollTo({ top: -1, left: 0, behavior: 'smooth' })
}

function buildFullPath(frontendRoutePath, path = null) {

    let fullPath

    const slug = Alpine.$router.params.slug

    switch (path) {

    case 'loading':
        fullPath = `${ frontendRoutePath }/${ slug }/loading`

        break

    case 'thank-you':
        fullPath = `${ frontendRoutePath }/${ slug }/thank-you`

        break

    default:
        fullPath = `${ frontendRoutePath }/${ slug }/${ path }`

    }

    if (window.location.search?.length) {
        fullPath += window.location.search
    }

    return fullPath

}
